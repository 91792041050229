import { sensorSwapEvents } from "src/data/events/logic/eventConstants"
import { TEventGroupValues } from "src/data/events/types/eventTypes"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"

type TEventOption = {
  label: string
  value: TEventGroupValues
}

export function eventOptionFilter({
  eventOption,
  availability,
}: {
  eventOption: TEventOption
  availability: {
    [Feature.CIGARETTE_SMOKE]: boolean
    [Feature.SENSOR_SWAP]: boolean
  }
}) {
  if (eventOption.value === "smoking_detection_smoking_detected") {
    return availability[Feature.CIGARETTE_SMOKE]
  }

  if (sensorSwapEvents.includes(eventOption.value)) {
    return availability[Feature.SENSOR_SWAP]
  }

  return true
}
